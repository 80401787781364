 @media screen and (min-width: 992px) {
  /*Desktop*/
  .hover-1-description {
    display: block;
  }

  .hover-1 .hover-overlay {
    background: rgba(0, 0, 0, 0.5);
  }


  div > div > div > div > a > div > div > p{
    display: block;
  }

  div > div > .introduction{
    margin-left: 20%;
    margin-right: 20%;
    padding: 7%;
    font-size: 22px;
    
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;

    border: 1px solid black;
  }

  .hover-1-description {
    background-color: green;
  }
  
  .hover-1-title {
    background-color: rgba(0, 128, 0, 0) !important;
  }

  .mobileview{
    display: none !important;
  }

  .desktopview{
    display: block !important;
    position: absolute;
    top: 25%;
  }

  .roundimg{
    border-radius: 50%;
    width: 450px !important;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    /*box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.445);*/
    box-shadow: rgb(0, 0, 0) 0px 7px 29px 1px;
  }
  

}
@media screen and (min-width: 600px) { .hover-1-title { font-size: 38px !important; } }


div > div > div > div > a >  div > div > p{
  display: none;
}

  .cardmargin{
    margin-top: 10%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .overflow{
    overflow-x: hidden;
  }

  .overtext{
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    /*border-radius: 25px;*/
    padding: 1.5%;
    margin-bottom: 0%;

  }

  .overtext2{
    padding: 1.5%;
    color: #fff;
    /*border-radius: 25px;*/
    background: rgba(0, 0, 0, 0.5);
  }

  .introduction{
    padding: 5%;
    margin-left: 3%;
    margin-right: 3%;
    font-size: 18px;
    
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border: 1px solid black;
  }

  .backimage {
    background-image: url("../../../public/images/wolkenkratzer.jpg");
    background-color: #cccccc;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .section1{
    height: 100vh;
    /*margin-top: -25%;*/
  }

  .roundimg{
    border-radius: 50%;
    width: 300px;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    /*box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.445);*/
    box-shadow: rgb(0, 0, 0) 0px 7px 29px 1px;
  }
  

  .placeholder {
    width: 5vw !important;
    background-color: rgba(0, 0, 0, 0);
  }

  table{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .desktopview{
    display: none;
    position: absolute;
    top: 25%;
  }

  .mobileview{
    display: block;
    position: absolute;
    top: 3%;
  }

  .fontborder{
    /*text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px black;*/
  }


  /* DEMO GENERAL ============================== */
.hover {
  overflow: hidden;
  position: relative;
  padding-bottom: 60%;
}

.hover-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
}

.hover img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.hover-content {
  position: relative;
  z-index: 99;
}


/* DEMO 1 ============================== */
.hover-1 img {
  width: 105%;
  position: absolute;
  top: 0;
  left: -5%;
  transition: all 0.3s;
}

.hover-1-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: all 0.4s;
}

/*
.hover-1 .hover-overlay {
  background: rgba(0, 0, 0, 0.5);
}
*/

.hover-1-description {
  transform: translateY(0.5rem);
  transition: all 0.4s;
  opacity: 0;
}

.hover-1:hover .hover-1-content {
  bottom: 2rem;
}

.hover-1:hover .hover-1-description {
  opacity: 1;
  transform: none;
}

.hover-1:hover img {
  left: 0;
}

.hover-1:hover .hover-overlay {
  opacity: 0;
}

.hover-1-description {
  background-color: rgba(0, 0, 0, 0.3);
}

.hover-1-title {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
}


.hover-1:hover .hover-1-description { background-color: rgba(0, 0, 0, 0.3); }
.hover-1:hover .hover-1-title { background-color: rgba(0, 0, 0, 0.3) !important;}


/*------Arrows---------*/


.arrows {
	width: 60px;
	height: 72px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: 20px;
}

.arrows path {
	stroke: #000;
	fill: transparent;
	stroke-width: 1px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-1s;
	-webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}

