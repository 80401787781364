body {
    overflow-x: hidden; /* Hide vertical scrollbar */
  }

.backtext{
    display: block;
    position: absolute;
    top: 30vh;
}

.backtext1{
    /*position: absolute;*/
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 1.5%;
    /*margin-bottom: 2%;*/

  }

  .backtext2{
    /*position: absolute;*/
    top: 10%;
    padding: 1.5%;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
  }

  /*
  .title1{
    margin-top: 80px;
    font-size: 30px;
    text-align: center;
    background-color: #545454b5;
    color: white;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
  */

/*
  .btn {
    min-width: 200px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    font-size: 1.4rem;
    border: 2px solid #222;
    padding: 0.3rem 1.2rem;
    text-transform: capitalize;
    color: inherit;
    transition: 0.4s;
  }
  
  .btn:hover{
    background-color: #222;
    color: #fff;
  }
  */
  


  @media screen and (min-width: 1020px) {
    .row {
      display: flex;
      flex-wrap: wrap;
    }
  
    .col-50 {
      flex-basis: 50%;
    }

    .desktopimg{
      display: block !important
    }

    .mobileimg{
      display: none !important;
    }

    .titles {
      font-size: 3rem !important;
      letter-spacing: 0.2rem;
      margin-bottom: 1rem;
    }
  
  }


  .img-text{
    max-width: none;
    background-color: none !important;
    margin-top: 80px;
  }
  
  /*remove block and see change at bottom*/
  .sectionimg {
    display: block;
    width: 103%;
    height: 70vh;
    object-fit: cover;
  }
  
  .articleimg {
    padding: 0 1.5rem;
  }
  
  .titles {
    font-size: 2rem;
    letter-spacing: none;
    margin-bottom: 1rem;
    text-align: center;
  }

  .subtitles{
    line-height: 1.6;
  }

  .mobileimg{
    display: block;
  }

  .desktopimg{
    display: none;
  }

  
  