@media screen and (min-width: 992px) {
    .pdffirst{
        margin-top: 150px !important; 
    }

    .pdf{
        width: 100px;
        margin-top: 5%;
        margin-left: 60%;
    }

    .h1line{
        width: 75%;
        margin-left: 12.5%;
        margin-right: 12.5%;
        border: 1px solid black;
    }

    .mobilepdf{
        margin-top: 5% !important;
    }

    .imgpdf{
        width: 100px;
    }

    .tablepdf{
        width: 55% !important;
        margin-left: 22.5% !important;
        margin-right: 22.5% !important;
    }
    
}

.pdffirst{
    margin-top: 150px !important; 
}

.pdf{
    width: 100px;
    margin-top: 5%;
    margin-left: 10%;
}

.h1line{
    width: 75%;
    margin-left: 12.5%;
    margin-right: 12.5%;
    border: 1px solid black;
}

.mobilepdf{
    margin-top: 20%;
}


.imgpdf{
    width: 100px;
}

.tablepdf{
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.td{
    width: 90%;
}