.impressspacing{
    margin-top: 20vh;
    margin-left: 10%;
    margin-right: 10%;
}

.impressspacing2{
    margin-bottom: 20vh;
    margin-left: 10%;
    margin-right: 10%;
}